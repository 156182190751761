<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}详情</div>
    <div class="table-operator">
      <!--      <div v-if="!isEmpty(vehicles)">-->
      <!--        对比车辆：-->
      <!--        <a-tag v-for="item in vehicles">{{ item.vehicle_name }}</a-tag>-->
      <!--      </div>-->
      <div style="margin: 10px 0;">
        对比时间：
        <a-radio-group v-model="queryParam.bill_type" @change="handleRefresh(true)">
          <a-radio :value="0">
            月度
          </a-radio>
          <a-radio :value="2">
            季度
          </a-radio>
          <a-radio :value="1">
            年度
          </a-radio>
        </a-radio-group>
      </div>
      <template>
        <div style="margin: 10px 0">
          当前年份：
          <!--          <a-date-picker-->
          <!--              format="YYYY年"-->
          <!--              mode="year"-->
          <!--              :value="queryParam.yearValue"-->
          <!--              :open="isopen"-->
          <!--              placeholder="选择年份"-->
          <!--              @change="onChange"-->
          <!--              @openChange="openChange"-->
          <!--              @panelChange="panelChange"-->
          <!--          />-->
          <a-radio-group
              v-model="queryParam.yearValue"
              :options="yearOptions"
              @change="handleRefresh(true)"
          />
        </div>
        <div v-if="queryParam.bill_type==0" style="margin: 10px 0">
          指定月份（不勾选默认展示全部月份数据）：
          <a-checkbox-group
              v-model="queryParam.monthValue"
              :options="plainOptions"
              @change="handleRefresh(true)"
          />
        </div>
        <div v-if="queryParam.bill_type==2" style="margin: 10px 0">
          选择季度：
          <a-radio-group
              v-model="queryParam.monthValue"
              :options="quarterOptions"
              @change="handleRefresh(true)"
          />
        </div>
      </template>
      <div style="margin: 10px 0;display: flex">
        对比数据：
        <div>
          <a-checkbox-group
              v-model="queryParam.zys"
              :options="zys"
              @change="renderTableColumns"
          />
          <div style="margin-bottom: 10px"></div>
          <a-checkbox-group
              v-model="queryParam.zcb"
              :options="zcb"
              @change="renderTableColumns"
          />
          <div style="margin-bottom: 10px"></div>
          <a-checkbox-group
              v-model="queryParam.lr"
              :options="lr"
              @change="renderTableColumns"
          />
        </div>
      </div>
    </div>

    <div style="margin: 10px 0;">
      排序规则：
      <a-radio-group v-model="queryParam.order_by" @change="handleRefresh(true)">
        <a-radio value="asc">
          由低至高
        </a-radio>
        <a-radio value="desc">
          由高至低
        </a-radio>
      </a-radio-group>
    </div>
    <div style="margin: 10px 0;">
      排序字段：
      <a-radio-group v-model="queryParam.order_field" @change="handleRefresh(true)">
        <a-radio value="total_revenue">
          总营收
        </a-radio>
        <a-radio value="total_expenditure">
          总成本
        </a-radio>
      </a-radio-group>
    </div>


    <template v-if="queryParam.type===0 ||(!isEmpty(vehicles) && !isEmpty(columns))">
      <s-table
          ref="table"
          rowKey="vb_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{x: `${columns.length}0%`,y:450}"
      >
        <div slot="price" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">¥</span> {{ text || '00.00' }}
        </div>

        <div slot="price2" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">¥</span> {{
            (item.total_revenue - (item.total_revenue * 0.65)).toFixed(2)
          }}
        </div>
        <div slot="price3" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">¥</span> {{
            (item.total_revenue - (item.total_revenue * 0.75)).toFixed(2)
          }}
        </div>
        <div slot="ton" slot-scope="text, item">
          {{ text || '0' }}吨
        </div>
        <div slot="xxxxx_loss" slot-scope="text, item">
          {{ text || '0' }}
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a @click="handleCommission(item)">费用明细</a>
        </div>
      </s-table>
    </template>
    <template v-else>
      <div style="height: 500px;display: flex;align-items: center;justify-content: center">
        <a-empty>
          <span slot="description"> 请在左侧车辆列表中点击您需要对比的车辆 </span>
        </a-empty>
      </div>
    </template>
    <CommissionModel
        ref="CommissionModel"
    />
  </a-card>
</template>

<script>
import * as Api from '@/api/statistics/vehicleBill'
import {STable} from '@/components'
import CommissionModel from "./modules/CommissionModel.vue";
import {isEmpty} from "../../../utils/util";
import moment from "moment";
import columns from "./columns";

export default {
  name: 'Index',
  components: {
    STable,
    CommissionModel,
  },
  data() {
    return {
      // 标题
      title: '',

      loading: false,

      isopen: false,
      yearOptions: [
        {label: '2023年', value: '2023-01-01'},
        {label: '2024年', value: '2024-01-01'},
      ],

      quarterOptions: [
        {label: '第一季度', value: ['01', '02', '03']},
        {label: '第二季度', value: ['04', '05', '06']},
        {label: '第三季度', value: ['07', '08', '09']},
        {label: '第四季度', value: ['10', '11', '23']},
      ],
      plainOptions: [
        {label: '1月', value: '01'},
        {label: '2月', value: '02'},
        {label: '3月', value: '03'},
        {label: '4月', value: '04'},
        {label: '5月', value: '05'},
        {label: '6月', value: '06'},
        {label: '7月', value: '07'},
        {label: '8月', value: '08'},
        {label: '9月', value: '09'},
        {label: '10月', value: '10'},
        {label: '11月', value: '11'},
        {label: '12月', value: '12'},
      ],
      zys: [
        {label: '总营收', value: 'total_revenue'},
        {label: '装货吨位', value: 'zhsl'},
        {label: '卸货吨位', value: 'xhsl'},
        {label: '损耗量', value: 'xxxxx_loss'},
      ],
      zcb: [
        {label: '总成本', value: 'total_expenditure'},
        {label: '加油费用', value: 'ck_refuel_amount'},
        {label: '保养费用', value: 'maintenance_amount'},
        {label: '轮胎费用', value: 'tire_amount'},
        {label: '尿素费用', value: 'urea_amount'},
        {label: '已申领工具费用', value: 'tool_amount'},
        {label: '装车/磅费', value: 'ov_zcbf'},
        {label: '修车/洗车费用', value: 'ov_xcxc'},
        {label: '其他费用', value: 'ov_qt'},
      ],
      lr: [
        {label: '利润', value: 'profit'},
        {label: '驾驶员工资', value: 'driver_salary'},
        {label: '押运员工资', value: 'supercargo_salary'},
      ],
      // 查询参数
      queryParam: {
        yearValue: moment().format("YYYY") + '-01-01',
        monthValue: '',
        vehicle_id: '',
        bill_type: 0,
        zys: ['total_revenue'],
        zcb: ['total_expenditure'],
        lr: ['profit'],
        type: 0,

        order_by: 'asc',
        order_field: 'total_revenue',
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.contrastList({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      vehicles: [],
    }
  },
  created() {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    this.queryParam.monthValue = [currentMonth < 10 ? '0' + currentMonth : currentMonth]
    this.renderTableColumns()
  },
  methods: {
    onChange() {
      this.queryParam.yearValue = null
    },
    openChange(isopen) {
      this.isopen = !!isopen
    },
    panelChange(value) {
      this.queryParam.yearValue = value
      this.isopen = false
      this.handleRefresh(true)
    },
    renderTableColumns() {
      const list = [...this.queryParam.zys, ...this.queryParam.zcb, ...this.queryParam.lr]
      var c = columns.list.filter(item => list.includes(item.dataIndex))
      if (this.queryParam.type) {
        this.columns = [...columns.fixed, ...c]
      } else {
        this.columns = [...c]
      }
    },
    isEmpty,

    /**
     * 设置当前选择的车辆
     */
    setVehicle(vehicles, vehicleIds, type) {
      this.vehicles = vehicles
      this.queryParam.vehicle_ids = vehicleIds
      this.queryParam.type = type

      this.$nextTick(() => {
        this.renderTableColumns()
        this.handleRefresh(true)
      })
    },

    /**
     * 费用明细
     */
    handleCommission(item) {
      this.$refs.CommissionModel.queryParam.vehicle_id = item.vehicle_id
      this.$refs.CommissionModel.queryParam.vb_id = item.vb_id
      this.$refs.CommissionModel.queryParam.month_time = item.month_time
      this.$refs.CommissionModel.queryParam.bill_type = item.bill_type
      this.$refs.CommissionModel.open(item)
    },

    /**
     * 关闭弹窗
     */
    handleCancel() {
      this.visible = false
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
