const fixed = [
    {
        align: 'center',
        title: '车牌号码',
        width: 100,
        dataIndex: 'vehicle_name',
    },
    {
        align: 'center',
        title: '时间',
        width: 180,
        dataIndex: 'month_str',
        scopedSlots: {customRender: 'month_str'}
    },
]

const list = [
    {
        align: 'center',
        title: '总营收',
        width: 140,
        dataIndex: 'total_revenue',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '装货吨位',
        width: 100,
        dataIndex: 'zhsl',
        scopedSlots: {customRender: 'ton'}

    },
    {
        align: 'center',
        title: '卸货吨位',
        width: 100,
        dataIndex: 'xhsl',
        scopedSlots: {customRender: 'ton'}

    },
    {
        align: 'center',
        title: '损耗量',
        width: 100,
        dataIndex: 'xxxxx_loss',
        scopedSlots: {customRender: 'xxxxx_loss'}
    },
    // {
    //     align: 'center',
    //     title: '实结数',
    //     width: 100,
    //     dataIndex: 'xhsl',
    // },
    // {
    //     align: 'center',
    //     title: '收入',
    //     width: 140,
    //     dataIndex: 'xxxxx_income',
    //     scopedSlots: {customRender: 'price'}
    // },
    {
        align: 'center',
        title: '总成本',
        width: 140,
        dataIndex: 'total_expenditure',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '加油费用',
        width: 100,
        dataIndex: 'ck_refuel_amount',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '保养费用',
        width: 100,
        dataIndex: 'maintenance_amount',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '轮胎费用',
        width: 100,
        dataIndex: 'tire_amount',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '尿素费用',
        width: 100,
        dataIndex: 'urea_amount',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '已申领工具费用',
        width: 140,
        dataIndex: 'tool_amount',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '装车/磅费',
        width: 100,
        dataIndex: 'ov_zcbf',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '修车/洗车费用',
        width: 140,
        dataIndex: 'ov_xcxc',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '其他费用',
        width: 100,
        dataIndex: 'ov_qt',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '利润',
        width: 140,
        dataIndex: 'profit',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '驾驶员工资',
        width: 140,
        dataIndex: 'driver_salary',
        scopedSlots: {customRender: 'price'}
    },
    {
        align: 'center',
        title: '押运员工资',
        width: 140,
        dataIndex: 'supercargo_salary',
        scopedSlots: {customRender: 'price'}
    },
    // {
    //   title: '操作',
    //   width: 300,
    //   dataIndex: 'action',
    //   scopedSlots: {customRender: 'action'}
    // }
]
export default {fixed, list}
